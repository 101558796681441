import React, { useContext, useEffect } from 'react';
import { Headings } from 'savills-ui-kit';
import {
  NoticePageBody,
  Logo,
  Header,
  MainContent,
  NoticeCTAs,
} from 'components/Notice/Notice.styled';
import { T9nContext, withT9nHook } from 'providers/T9nProvider/t9nContext';
import { MysLink } from 'components/Link/Link';
import { broadcastRemoveSessionMessage } from 'services/logout';
import loggedOutT9n from 'queries/t9n/loggedOutT9n.gql';
import Footer from 'components/Footer/Footer';
import { HeaderFooterContext } from 'providers/HeaderFooter/HeaderFooterContext';

const LoggedOutComponent = () => {
  const { t9n } = useContext(T9nContext);

  const {
    footerData,
    headerData,
    logoUrl: homeUrl,
  } = useContext(HeaderFooterContext);

  useEffect(() => {
    const logout = async () => {
      await broadcastRemoveSessionMessage();
    };
    logout();

    document.body.classList.add('hide-hj');
    return () => {
      document.body.classList.remove('hide-hj');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Header>
        <a href={homeUrl} aria-label="Savills UK Home">
          <Logo title="Savills Logo" />
        </a>
      </Header>
      <MainContent>
        <Headings.PageHeading>{t9n.LoggedOut.Title}</Headings.PageHeading>
        <NoticePageBody>{t9n.LoggedOut.Message}</NoticePageBody>
        <NoticeCTAs>
          <MysLink as="a" href={homeUrl}>
            {t9n.Applicant.Buttons.StartSearch}
          </MysLink>
        </NoticeCTAs>
      </MainContent>

      <Footer
        headerContent={headerData || undefined}
        footerContent={footerData || undefined}
      />
    </div>
  );
};

export const LoggedOut = withT9nHook(LoggedOutComponent, loggedOutT9n, [
  'LoggedOut',
]);
