import React, { useContext, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { getLocalizationParametersFromCookieOrUrl } from 'utils/helpers/localization/localization';
import { ROUTES } from 'constants/routes';
import { DEFAULT_LOCALE_PARAMS } from 'constants/global';
import { getSuffix } from 'helpers/helpers';
import { AccountStateContext } from 'providers/Account/AccountState';
import { PropertyTypes } from './constants';
import { PropertyLine } from './PropertyLine';
import {
  HouseIcon,
  PeopleIcon,
  PropertyList,
  PropertyNavItem,
  PropertyNavTitle,
  PropertyNavItemDefault,
  PropertyNavItemDisabled,
} from './Sidebar.styled';
import { PropertyNavBlockProps } from './types';

export const PropertyNavBlock = ({
  properties,
  type,
  title,
  toggleSidebar,
  selectedPropertyId,
}: PropertyNavBlockProps) => {
  const urlParams = useLocation();
  const pathName = urlParams.pathname;
  const [propertyListOpen, setPropertyListOpen] = useState(false);

  const { state } = useContext(AccountStateContext);
  const isPreview = state?.isPreview;
  const isUserConnected = state?.user?.isUserConnected;

  useMemo(() => {
    const containsSelectedProperty = properties?.find(
      (property) => property.id === selectedPropertyId,
    );
    if (containsSelectedProperty) {
      setPropertyListOpen(true);
    }
  }, [selectedPropertyId, properties]);

  const togglePropertyList = () => {
    setPropertyListOpen(!propertyListOpen);
  };

  const { countryCode } = getLocalizationParametersFromCookieOrUrl();

  const isUK = countryCode === DEFAULT_LOCALE_PARAMS.COUNTRY;

  if (
    (type === PropertyTypes.SELLING &&
      properties &&
      properties.length === 0 &&
      isUK) ||
    (!isUserConnected && !isPreview && type === PropertyTypes.SELLING)
  ) {
    return (
      <PropertyNavItemDefault isActive={pathName === ROUTES.NO_PROPERTIES}>
        <Link
          onClick={toggleSidebar}
          data-testid={`${type}List`}
          to={ROUTES.NO_PROPERTIES}
        >
          <HouseIcon /> <span>{title}</span>
        </Link>
      </PropertyNavItemDefault>
    );
  }

  if (properties?.length === 0 && !isUK) {
    return null;
  }

  if (properties?.length === 0 || (!isUserConnected && !isPreview)) {
    return (
      <PropertyNavItemDisabled>
        <PeopleIcon /> <span>{title}</span>
      </PropertyNavItemDisabled>
    );
  }

  return (
    <PropertyNavItem>
      <PropertyNavTitle
        data-testid={`${type}List`}
        isOpen={propertyListOpen}
        onClick={togglePropertyList}
      >
        {type === PropertyTypes.SELLING ? <HouseIcon /> : <PeopleIcon />}
        <span>
          {title} {properties && getSuffix(properties)}
        </span>
      </PropertyNavTitle>
      <PropertyList items={properties?.length || 0} isOpen={propertyListOpen}>
        {properties?.map(({ id, address }) => (
          <PropertyLine
            toggleSidebar={toggleSidebar}
            key={id}
            id={id}
            address={address}
            route={
              type === PropertyTypes.SELLING
                ? ROUTES.VENDORS.ROOT
                : ROUTES.LETTING.ROOT
            }
            selectedPropertyId={selectedPropertyId}
          />
        ))}
      </PropertyList>
    </PropertyNavItem>
  );
};
