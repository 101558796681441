export const ROUTES = {
  HOME: '/',
  BUCKET_INDEX: '/index.html',
  DOCUMENTS: {
    ROOT: '/documents',
    UPLOAD: '/upload',
  },
  VENDORS: {
    ROOT: '/vendor',
    FEEDBACK: '/vendor/feedback',
  },
  LETTING: {
    ROOT: '/landlord',
    FEEDBACK: '/landlord/feedback',
    PORTFOLIO: '/landlord/portfolio',
  },
  APPLICANT: {
    ROOT: '/applicant',
    RECOMMENDED: '/applicant#recommendedProperties',
    ALERTS: '/applicant#propertyAlerts',
    SAVED: '/applicant#savedProperties',
    RECORDS: '/applicant#propertyRecords',
    OFFERS: '/applicant/offers',
    SAVED_PROPERTIES: '/applicant/saved-properties',
    VIEWINGS: '/applicant/viewings',
  },
  NOTIFICATIONS: '/notifications',
  CALENDAR: '/calendar',
  ACCOUNT: '/account',
  BOOKING: '/booking/:appointmentLinkId',
  UNSUBSCRIBE: '/unsubscribe',
  ERROR: '/error',
  LOGGED_OUT: '/logged-out',
  FORBIDDEN: '/forbidden',
  NO_PROPERTIES: '/selling-property',
};

export const SAVILLS_UK_HOME = 'https://www.savills.co.uk/';
