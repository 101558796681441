import { T9nHubResource } from 'providers/T9nProvider/types/t9n';
import { ValidationProps } from 'savills-ui-kit/lib/components/Input/types';
import { PropertyList } from 'types/app';

export enum ReapitEntityTypes {
  PROPERTY = 'property',
  CONTACT = 'contact',
  TENANCY = 'tenancy',
  CERTIFICATE = 'certificate',
}

export interface SignatureSense {
  documentStatus: string;
  documentIssued: string;
  documentExecuted: string;
  signatoryStatus: string;
  documentUri: string;
}

export enum UploadStatus {
  Processing = 'Processing',
  FailedVirusScan = 'FailedVirusScan',
  Failed = 'Failed',
}

export interface ReapitTenancyDetails {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  status: string;
}

export interface ReapitDocument {
  id: string;
  title: string;
  name: string;
  url?: string;
  typeId: string;
  associatedType: ReapitEntityTypes;
  associatedId: string;
  created: Date;
  modified: Date;
  modifiedOn: string;
  upload: UploadStatus;
  signatureSense?: SignatureSense;
  uploadStatus?: UploadStatus;
  address?: string;
  tenancyDetails?: ReapitTenancyDetails;
  type?: string;
  category?: string;
  certificateInfo?: {
    id: string;
    propertyId: string;
    category: string;
    type: string;
    startDate: string;
    endDate: string;
  };
}

export enum SortBy {
  DATE_ASC = 'date-asc',
  DATE_DESC = 'date-desc',
  NAME_ASC = 'name-asc',
  NAME_DESC = 'name-desc',
}

type ReverseMap<T> = T[keyof T];

export type SortValue = ReverseMap<typeof SortBy>;

export enum UploadFieldTypes {
  RELATED_PROPERTIES = 'relatedProperties',
  SINGLE_RELATED_PROPERTY = 'relatedProperty',
  RADIO = 'radio',
  SELECT = 'select',
  TEXTAREA = 'textarea',
  UPLOAD = 'upload',
}

export enum UploadFormSteps {
  HAS_ONE_RELATED_PROPERTY = 'hasOneRelatedProperty',
  HAS_RELATED_PROPERTIES = 'hasRelatedProperties',
  RELATED_PROPERTIES = 'relatedProperties',
  DOCUMENT_TYPE = 'documentType',
  NOTES = 'notes',
  UPLOAD_FILES = 'uploadFiles',
  CONFIRM_ADD_ANOTHER = 'confirm',
}

type UploadFieldContent = {
  Legend: string;
  Label?: string;
  Labels?: string[];
  Info?: string;
  Error?: string;
};

export type OptionProps = {
  key: string;
  value: string;
  label: string;
};

export type UploadInputProps = {
  key: string;
  value: string;
  content: UploadFieldContent;
  fieldType: UploadFieldTypes;
  options: OptionProps[];
  validation: ValidationProps;
  hasRequirements?: boolean;
  isEditable?: boolean;
  isVisible?: boolean;
};

export type FieldProps = {
  t9n: T9nHubResource;
  field: UploadInputProps;
  changeHandler: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    key: string,
  ) => void;
  propertyList?: PropertyList;
  hasRelatedProperties?: boolean;
  handleFileUpload?: (e: File[]) => void;
  hasOneRelatedProperty?: string;
};
