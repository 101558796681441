import { DEFAULT_MAP_SETTINGS } from 'components/PropertyCard/Map/constants';
import { MapDimensionProps } from './types';

export const getPropertyPrice = (priceFrom?: string, priceTo?: string) => {
  if (priceFrom && priceTo) {
    return `${priceFrom} - ${priceTo}`;
  }

  if (priceFrom) {
    return `${priceFrom}+`;
  }

  if (priceTo) {
    return `< ${priceTo}`;
  }

  return '';
};

export const getPropertySize = (
  type: string,
  min?: number,
  max?: number,
  size?: number,
) => {
  if (min && max) {
    return `${min} - ${max} ${type}`;
  }
  if (min) {
    return `${min}+ ${type}`;
  }
  if (max) {
    return `< ${max} ${type}`;
  }
  if (size) {
    return `${size} ${type}`;
  }
  return '';
};

const latRad = (lat: number) => {
  const sin = Math.sin((lat * Math.PI) / 180);
  const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
  return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
};

const zoom = (mapPx?: number, worldPx?: number, fraction?: number) => {
  if (!mapPx || !worldPx || !fraction) {
    return DEFAULT_MAP_SETTINGS.zoom;
  }
  return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
};

export const getBoundsZoomLevel = (
  bounds: google.maps.LatLngBounds,
  mapDim: MapDimensionProps,
) => {
  const WORLD_DIM = { height: 256, width: 256 };
  const ZOOM_MAX = 9;
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

  const lngDiff = ne.lng() - sw.lng();
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
  const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
};

const getAddress = (address: string) => {
  return new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status === 'OK') {
        resolve(results[0] as google.maps.GeocoderResult);
      } else {
        reject(status);
      }
    });
  });
};

export const getBounds = async (location: string) => {
  const results = (await getAddress(
    location,
  )) as unknown as google.maps.GeocoderResult;
  const centerBounds = results.geometry.bounds.getCenter();
  return {
    center: {
      lat: centerBounds.lat(),
      lng: centerBounds.lng(),
    },
    bounds: results.geometry.bounds,
  };
};

export const getCenter = async (map: string[]) => {
  const bound = new google.maps.LatLngBounds();
  const collectionOfPoints = map.reduce(
    (accum: google.maps.LatLng[], geolocation: string) => {
      const areaPointsArr = geolocation.split(',');
      if (
        Number.isNaN(parseFloat(areaPointsArr[0])) ||
        Number.isNaN(parseFloat(areaPointsArr[1]))
      ) {
        return accum;
      }

      return [
        ...accum,
        new google.maps.LatLng(
          parseFloat(areaPointsArr[0]),
          parseFloat(areaPointsArr[1]),
        ),
      ];
    },
    [],
  );

  if (!collectionOfPoints) {
    return null;
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < collectionOfPoints?.length; i++) {
    bound.extend(collectionOfPoints[i]);
  }

  return {
    center: {
      lat: bound.getCenter().lat(),
      lng: bound.getCenter().lng(),
    },
    bounds: bound,
  };
};
