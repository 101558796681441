import React from 'react';

import { formatStreetAddress } from 'helpers/helpers';
import { Link } from 'react-router-dom';
import { PropertyLineItem } from './Sidebar.styled';
import { PropertyLineProps } from './types';

export const PropertyLine = ({
  address,
  route,
  id,
  selectedPropertyId,
  toggleSidebar,
}: PropertyLineProps) => {
  const streetAddress = formatStreetAddress(address);

  return (
    <PropertyLineItem isSelected={selectedPropertyId === id} key={id}>
      <Link
        onClick={toggleSidebar}
        aria-label={`${streetAddress}`}
        to={`${route}/${id}`}
      >
        {streetAddress}
      </Link>
    </PropertyLineItem>
  );
};
