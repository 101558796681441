import React from 'react';
import { Link } from 'react-router-dom';
import { PropertyNav } from './PropertyNav';
import {
  SidebarNavContent,
  SidebarNavItem,
  SidebarDisabledLink,
} from './Sidebar.styled';
import { IconAlignment, NavItemProps } from './types';

export const NavItem = ({
  item,
  propertyList,
  toggleSidebar,
  flags,
  icon: Icon,
  isActive,
  isDisabled,
}: NavItemProps) => {
  if (item.isHidden) {
    return null;
  }

  if (isDisabled) {
    return (
      <SidebarNavItem
        key={item.key}
        isFixedToBottom={item.isFixedToBottom}
        isUppercase={item.isUppercase}
        iconRightAligned={item.icon?.align === IconAlignment.RIGHT}
        isPropertyItem={item.isProperties}
        isActive={isActive}
        isDisabled
      >
        <SidebarNavContent isActive={isActive}>
          <SidebarDisabledLink>
            {Icon && item?.icon?.align === IconAlignment.LEFT && <Icon />}
            {item.label}
            {item.isProperties && item.label}
            {Icon && item?.icon?.align === IconAlignment.RIGHT && <Icon />}
          </SidebarDisabledLink>
        </SidebarNavContent>
      </SidebarNavItem>
    );
  }

  return (
    <SidebarNavItem
      key={item.key}
      isFixedToBottom={item.isFixedToBottom}
      isUppercase={item.isUppercase}
      iconRightAligned={item.icon?.align === IconAlignment.RIGHT}
      isPropertyItem={item.isProperties}
      isActive={isActive}
    >
      <SidebarNavContent onClick={toggleSidebar} isActive={isActive}>
        {item.route && (
          <Link to={item.route}>
            {Icon && item?.icon?.align === IconAlignment.LEFT && <Icon />}
            {item.label}
            {item.isProperties && item.label}
            {Icon && item?.icon?.align === IconAlignment.RIGHT && <Icon />}
          </Link>
        )}
      </SidebarNavContent>
      {item.isProperties && (
        <PropertyNav
          propertyList={propertyList}
          item={item}
          flags={flags}
          toggleSidebar={toggleSidebar}
        />
      )}
    </SidebarNavItem>
  );
};
