import { PropertyList, SlimProperty } from 'types/app';
import { PropertyTypes } from './constants';

export interface NavAddress {
  buildingName?: string;
  buildingNumber?: string;
  line1?: string;
  postcode?: string;
}

export interface SidebarProps {
  selectedPropertyId?: string;
  hasNotifications?: boolean;
  hasCalendar?: boolean;
  propertyList?: PropertyList;
  isUserConnected?: boolean;
  isPreview?: boolean;
}

export interface NavigationSectionProps {
  title: string;
  path: string;
  icon: React.FunctionComponent;
  selectedPropertyId?: string;
  properties?: SlimProperty[];
  toggleSidebar: () => void;
}

export interface NavigationItemProps {
  icon: React.FunctionComponent;
  title: string;
  isOpen?: boolean;
  to?: string;
  children?: React.ReactNode[];
  onClick?: () => void;
}

export enum SidebarNavItems {
  NOTIFICATION = 'notification',
  CALENDAR = 'calendar',
  DOCUMENTS = 'documents',
  APPLICANT = 'applicant',
  PROPERTIES = 'properties',
  ACCOUNT = 'account',
}

export enum IconAlignment {
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
}

export interface NavigationListItemProps {
  key: SidebarNavItems;
  label: string;
  route?: string | { [k: string]: string };
  isFixedToBottom?: boolean;
  isProperties?: boolean;
  isUppercase?: boolean;
  icon?: {
    align: IconAlignment;
    icon: React.FunctionComponent;
  };
  isHidden?: boolean;
}

export type PropertyRoute =
  | string
  | {
      [k: string]: string;
    };

export interface NavItemProps {
  item: NavigationListItemProps;
  propertyList?: PropertyList;
  selectedPropertyId?: string;
  toggleSidebar: () => void;
  flags: { [key: string]: boolean };
  icon?: React.FunctionComponent;
  isActive?: boolean;
  isDisabled?: boolean;
}
export interface PropertyNavProps {
  item: NavigationListItemProps;
  propertyList?: PropertyList;
  flags: { [key: string]: boolean };
  toggleSidebar: () => void;
}

export interface PropertyLineProps {
  address: NavAddress;
  route?: PropertyRoute;
  id: string;
  toggleSidebar: () => void;
  selectedPropertyId?: string;
}

export interface PropertyNavBlockProps {
  properties?: SlimProperty[];
  type: PropertyTypes;
  title: string;
  toggleSidebar: () => void;
  route?: PropertyRoute;
  selectedPropertyId?: string;
}

export interface SidebarContainerProps {
  isOpen?: boolean;
}
export interface SidebarItemProps {
  isUppercase?: boolean;
  isFixedToBottom?: boolean;
  iconRightAligned?: boolean;
  isPropertyItem?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
}
