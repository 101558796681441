import styled, { css } from 'styled-components';
import { hideVisually } from 'polished';
import styledTheme from 'styled/theme';
import { ReactComponent as SavillsLogo } from 'assets/icons/savills-square.svg';
import { ReactComponent as BellSvg } from 'assets/icons/bell.svg';
import { ReactComponent as CalendarSvg } from 'assets/icons/calendar.svg';
import { ReactComponent as DocumentSvg } from 'assets/icons/document.svg';
import { ReactComponent as HouseSvg } from 'assets/icons/house.svg';
import { ReactComponent as PeopleSvg } from 'assets/icons/people.svg';
import { ReactComponent as UserSvg } from 'assets/icons/user.svg';
import { SidebarContainerProps, SidebarItemProps } from './types';

const DRAWER_ANIMATION_TIME = '0.4s';
const DRAWER_ANIMATION_EASING = 'ease';
const PROPERTY_LINE_HEIGHT = '31px';

export const SIDEBAR_WIDTHS = {
  sml: '223px',
  lrg: '320px',
};

function withIconStyles(Icon: React.FC, fill: string, stroke: string) {
  return styled(Icon)`
    position: relative;
    box-sizing: content-box;
    width: 16px;
    height: 16px;
    margin: 0;
    fill: ${fill};
    stroke: ${stroke};
  `;
}

function withSuffixIconStyles(Icon: React.FC) {
  return styled(Icon)`
    margin: 0 0 0 auto;
  `;
}

export const SidebarControl = styled.div`
  width: 100vw;
  height: 56px;
  background: ${({ theme }) => theme.hub.colorPalette.primary.white};
  position: sticky;
  top: 0;
  z-index: 1000;

  @media screen and (${(props) => props.theme.media.widthSml}) {
    display: none;
  }
`;

export const SidebarMenuButton = styled.button`
  width: 56px;
  height: 56px;
  margin: 0 10px;
  border: none;
  outline: none;
  background: none;
  position: fixed;
  top: 0;

  svg {
    fill: ${(props) => props.theme.colorPalette.branding.white};
  }

  &:hover,
  &:focus {
    opacity: 0.5;
  }

  svg {
    fill: ${({ theme }) => theme.hub.colorPalette.primary.white};
  }
`;

export const SidebarWrapper = styled.div<SidebarContainerProps>`
  grid-area: sidebar;
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.hub.colorPalette.primary.darkBlue};
  color: ${(props) => props.theme.hub.colorPalette.primary.white};
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-weight: ${(props) => props.theme.fonts.primary.fontWeight};
  height: 100%;
  width: calc(100vw);
  ${({ isOpen }) => (isOpen ? `left: 0;` : `left: -100vw;`)}
  transition: left 0.3s ease;

  @media screen and (${(props) => props.theme.media.widthSml}) {
    left: 0;
    max-width: ${SIDEBAR_WIDTHS.sml};
  }
  @media screen and (${(props) => props.theme.media.widthXXlrg}) {
    max-width: ${SIDEBAR_WIDTHS.lrg};
  }
`;

export const Logo = styled(SavillsLogo)`
  height: 50px;
  width: 50px;

  @media screen and (${(props) => props.theme.media.widthSml}) {
    height: 66px;
    width: 66px;
  }
`;

export const SidebarCloseButton = styled(SidebarMenuButton)`
  position: absolute;
  top: 24px;
  right: 0;
  @media screen and (${(props) => props.theme.media.widthSml}) {
    display: none;
  }

  &:hover,
  &:focus {
    opacity: 1;
    svg {
      fill: ${(props) => props.theme.colorPalette.branding.yellow};

      path {
        fill: ${(props) => props.theme.colorPalette.branding.yellow};
      }
    }
  }
`;

export const SidebarHeader = styled.header`
  padding: 24px 30px 5px;
  a {
    line-height: 0;
    display: inline-block;
  }
`;

export const SidebarNav = styled.ul`
  margin: 0;
  display: flex;
  height: 100%;
  flex-flow: column;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 2px;
    background: 1px solid ${(props) => props.theme.colorPalette.branding.white};
  }
`;

export const SidebarNavItem = styled.li<SidebarItemProps>`
  font-weight: ${(props) =>
    props.isUppercase
      ? props.theme.fonts.secondary.fontWeightMed
      : props.theme.fonts.secondary.fontWeight};
  text-transform: ${(props) => (props.isUppercase ? 'uppercase' : 'none')};
  font-size: ${(props) => props.theme.hub.fontSize.menu.medium};
  border-top: 1px solid
    ${(props) => props.theme.hub.colorPalette.primary.greyBlue};
  position: relative;
  width: 100%;
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};

  a {
    text-decoration: none;
    color: ${(props) => props.theme.hub.colorPalette.primary.white};
  }

  &:first-child {
    border-top: 0;
    border-bottom: 0;
  }
  ${(props) =>
    props.isFixedToBottom &&
    css`
      margin-top: auto;
    `}

  ${(props) =>
    props.isPropertyItem &&
    css`
      flex-flow: column wrap;
    `}


  ${(props) =>
    props.isUppercase &&
    css`
      letter-spacing: 2px;
      font-size: ${props.theme.typography.xsmall};
    `}

${(props) => props.iconRightAligned && `justify-content: space-between;`}


  ${(props) =>
    props.isActive &&
    css`
      background: ${props.theme.colorPalette.secondary.lightInk};
      border-left: 7px solid ${props.theme.colorPalette.branding.yellow};
    `}
`;

export const SidebarNavContent = styled.span<{ isActive?: boolean }>`
  a {
    padding: ${(props) => (props.isActive ? '24px 23px' : '24px 30px')};
    display: flex;
    flex-flow: row nowrap;
    grid-gap: 10px;
    position: relative;
    z-index: 10;

    &:hover,
    &:focus {
      color: ${(props) => props.theme.colorPalette.branding.yellow};
    }
  }
`;

export const PropertyNavList = styled.ul`
  letter-spacing: normal;
  font-size: ${(props) => props.theme.typography.small};
  text-transform: none;
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
  margin: 0;
`;

export const PropertyNavHeader = styled.span`
  padding: 24px 30px;
  display: flex;
  flex-flow: row nowrap;
  grid-gap: 10px;
`;

export const PropertyNavItem = styled.li`
  grid-gap: 5px;
  width: 100%;
  margin: 0 0 10px;
`;

export const PropertyNavItemDefault = styled(PropertyNavItem)<{
  isActive?: boolean;
}>`
  padding: 7px 10px 7px 23px;

  a {
    display: flex;
    grid-gap: 10px;
    align-items: center;
    padding: 0 0 0 8px;

    span {
      padding-top: 2px;
    }

    &:hover,
    &:focus {
      color: ${(props) => props.theme.colorPalette.branding.yellow};
    }
  }

  ${(props) =>
    props.isActive &&
    `
      background: ${props.theme.colorPalette.secondary.lightInk};
      border-left: 7px solid ${props.theme.colorPalette.branding.yellow};
    `}
`;

export const PropertyNavItemDisabled = styled(PropertyNavItem)`
  padding: 7px 10px 7px 30px;
  opacity: 0.5;

  display: flex;
  grid-gap: 10px;
  align-items: center;

  span {
    padding-top: 2px;
  }
`;

export const PropertyNavTitle = styled.button<{ isOpen?: boolean }>`
  display: flex;
  grid-gap: 10px;
  padding: 0 0 0 30px;
  width: 100%;
  background: none;
  border: 0;
  color: ${(props) => props.theme.colorPalette.branding.white};
  text-align: left;
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-size: ${(props) => props.theme.hub.fontSize.menu.medium};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};

  &:hover,
  &:focus {
    color: ${(props) => props.theme.colorPalette.branding.yellow};
    &::after {
      color: ${(props) => props.theme.colorPalette.branding.yellow};
    }
  }

  &::after {
    content: ${(props) => (props.isOpen ? '"-"' : '"+"')};
    position: absolute;
    right: 60px;
    font-weight: ${(props) => props.theme.fonts.secondary.fontWeightBold};
    font-size: ${(props) => props.theme.hub.fontSize.menu.large};
  }
`;

export const PropertyList = styled.ul<{ items: number; isOpen: boolean }>`
  height: 0;
  overflow: hidden;
  margin: 20px 0 0;
  opacity: 0;

  ${({ isOpen, items }) =>
    isOpen &&
    `
    opacity: 1;
    height: calc(${items} * ${PROPERTY_LINE_HEIGHT});
  `};
  transition: all ${DRAWER_ANIMATION_TIME} ${DRAWER_ANIMATION_EASING};
`;

export const PropertyLineItem = styled.li<{ isSelected?: boolean }>`
  display: inline-block;
  width: 100%;
  font-size: ${({ theme }) => theme.hub.fontSize.menu.small};
  text-align: left;
  font-weight: ${(props) => props.theme.fonts.primary.fontWeight};

  a {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 7px 10px 7px 50px;
    background: ${(props) =>
      props.isSelected
        ? props.theme.colorPalette.secondary.lightInk
        : props.theme.colorPalette.branding.ink};
    border-left: 4px solid
      ${(props) =>
        props.isSelected
          ? props.theme.colorPalette.branding.yellow
          : props.theme.colorPalette.branding.ink};
    &:hover,
    &:focus {
      color: ${(props) => props.theme.colorPalette.branding.yellow};
    }
  }
`;

export const BellIcon = withIconStyles(
  BellSvg,
  styledTheme.colorPalette.branding.white,
  'none',
);
export const CalendarIcon = withIconStyles(
  CalendarSvg,
  styledTheme.colorPalette.branding.white,
  'none',
);
export const DocumentIcon = withIconStyles(
  DocumentSvg,
  styledTheme.colorPalette.branding.white,
  'none',
);
export const HouseIcon = withIconStyles(
  HouseSvg,
  styledTheme.colorPalette.branding.white,
  'none',
);
export const PeopleIcon = withIconStyles(
  PeopleSvg,
  'none',
  styledTheme.colorPalette.branding.white,
);
export const UserIcon = withSuffixIconStyles(
  withIconStyles(UserSvg, 'none', styledTheme.colorPalette.branding.white),
);

export const VisuallyHidden = styled.span`
  ${hideVisually}
`;

export const SidebarAccountNav = styled.ul`
  border-top: 1px solid
    ${(props) => props.theme.hub.colorPalette.primary.greyBlue};
`;

export const SidebarDisabledLink = styled.span`
  cursor: disabled;
  color: ${(props) => props.theme.hub.colorPalette.primary.greyBlue};
  padding: 24px 30px;
  display: flex;
  flex-flow: row nowrap;
  grid-gap: 10px;
  position: relative;
  z-index: 10;
  opacity: 0.5;

  align-items: center;
`;
