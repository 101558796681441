import { PropertyList } from 'types/app';
import { DocumentStatus } from 'components/DocumentListItem/types';

export const getMergedProperties = (propertyList: PropertyList) => {
  if (propertyList?.letting && propertyList?.selling) {
    return [...propertyList.selling, ...propertyList.letting];
  }

  if (propertyList?.selling) {
    return [...propertyList.selling];
  }

  return [];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const shouldAllowDownload = (url?: string, signatureSense?: any) => {
  const fullySigned =
    signatureSense?.documentStatus === DocumentStatus.SIGNED_EXECUTED;

  const hasSignatureSense = !!signatureSense;
  const hasDownloadLink = !!url;

  return hasDownloadLink && (!hasSignatureSense || fullySigned);
};
